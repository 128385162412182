import MDEditor from "@uiw/react-md-editor";
import { useMemo } from "react";
import { default as Monaco } from "@monaco-editor/react";

const Editor = (props) => {
	const textArea = useMemo(
		() => (
			<textarea
				value={props.markdown}
				onChange={(e) => props.onChange(e.target.value)}
			/>
		),
		[props],
	);

	const reactMdEditor = useMemo(
		() => (
			<MDEditor
				value={props.markdown}
				onChange={props.onChange}
				preview="edit"
				hideToolbar
			/>
		),
		[props],
	);

	const monacoEditor = useMemo(
		() => (
			<Monaco
				value={props.markdown}
				onChange={props.onChange}
				language="markdown"
			/>
		),
		[props],
	);

	const editors = useMemo(() => {
		return { textArea, reactMdEditor, monacoEditor };
	}, [textArea, reactMdEditor, monacoEditor]);

	return editors[props.editor] ?? null;
};

export default Editor;
