import md from "./markdown-sample.md";
import "./App.css";
import { useEffect, useState } from "react";
import Editor from "./Editor";
import Viewer from "./Viewer";

function App() {
	const [markdown, setMarkdown] = useState("");
	const [viewer, setViewer] = useState("reactMarkdown");
	const [editor, setEditor] = useState("textArea");
	const [styling, setStyling] = useState();

	useEffect(() => {
		const fetchMarkdown = async () => {
			const response = await fetch(md);
			const text = await response.text();
			setMarkdown(text);
		};
		fetchMarkdown();
	}, []);

	const viewers = [
		"reactMarkdown",
		"reactRemark",
		"remarkable",
		"reactMdEditor",
		"reactMarkdownGfm",
		"markdownToJSX",
		"markdownIt",
		"marked",
	];

	const editors = ["textArea", "reactMdEditor", "monacoEditor"];

	return (
		<div style={{ display: "flex", gap: 4, flexDirection: "column" }}>
			<div className="flex">
				{editors.map((editor) => (
					<button onClick={() => setEditor(editor)}>{editor}</button>
				))}
				<p>Current Editor: {editor}</p>
			</div>
			<div className="flex">
				{viewers.map((viewer) => (
					<button onClick={() => setViewer(viewer)}>{viewer}</button>
				))}
				<p>Current Viewer: {viewer}</p>
			</div>
			<div className="flex">
				<button
					onClick={() =>
						setStyling((prev) =>
							prev == null ? (
								<link rel="stylesheet" href="/style.css" type="text/css" />
							) : null,
						)
					}
				>
					Turn {styling == null ? "on" : "off"} styling
				</button>
				{styling}
			</div>
			<div className="App">
				<Editor
					editor={editor}
					markdown={markdown}
					onChange={(value) => setMarkdown(value)}
				/>
				<div>
					<Viewer viewer={viewer} markdown={markdown} />
				</div>
			</div>
		</div>
	);
}

export default App;
