import { useEffect, useMemo } from "react";
import { Remark } from "react-remark";
import { Remarkable } from "remarkable";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import Markdown from "markdown-to-jsx";
import MDEditor from "@uiw/react-md-editor";
import { default as markedView } from "marked";
import Prism from "prismjs";

const MarkdownIt = require("markdown-it")();

var remarkableInst = new Remarkable();

const Viewer = (props) => {
	useEffect(() => {
		Prism.highlightAll();
	}, [props.highlighting]);

	const reactMarkdown = useMemo(
		() => <ReactMarkdown>{props.markdown}</ReactMarkdown>,
		[props.markdown],
	);

	const reactMarkdownGfm = useMemo(
		() => <ReactMarkdown plugins={[remarkGfm]}>{props.markdown}</ReactMarkdown>,
		[props.markdown],
	);

	const reactRemark = useMemo(
		() => <Remark>{props.markdown}</Remark>,
		[props.markdown],
	);

	const markdownToJSX = useMemo(
		() => (
			<Markdown
				options={{
					disableParsingRawHTML: true,
				}}
			>
				{props.markdown}
			</Markdown>
		),
		[props.markdown],
	);

	const remarkable = useMemo(() => {
		return (
			<div
				dangerouslySetInnerHTML={{
					__html: remarkableInst.render(props.markdown),
				}}
			/>
		);
	}, [props.markdown]);

	const marked = useMemo(() => {
		return (
			<div
				dangerouslySetInnerHTML={{
					__html: markedView(props.markdown),
				}}
			/>
		);
	}, [props.markdown]);

	const reactMdEditor = useMemo(
		() => <MDEditor.Markdown source={props.markdown} />,
		[props.markdown],
	);

	const markdownIt = useMemo(() => {
		return (
			<div
				dangerouslySetInnerHTML={{
					__html: MarkdownIt.render(props.markdown),
				}}
			/>
		);
	}, [props.markdown]);

	const viewers = useMemo(() => {
		return {
			reactMarkdown,
			reactRemark,
			remarkable,
			reactMdEditor,
			reactMarkdownGfm,
			markdownToJSX,
			markdownIt,
			marked,
		};
	}, [
		reactMdEditor,
		reactRemark,
		remarkable,
		reactMarkdown,
		markdownToJSX,
		reactMarkdownGfm,
		markdownIt,
		marked,
	]);

	return <div>{viewers[props.viewer] ?? null}</div>;
};

export default Viewer;
